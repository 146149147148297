import React from "react";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  return (
    <div className="bg-rose-600 text-white p-4 mt-auto w-full">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center text-center md:text-base space-y-4 md:space-y-0">
        {/* Copyright Notice - For desktop, this needs to be first in the HTML but visually second */}
        <div className="text-xs w-full md:w-auto md:order-1 order-2">
          © 2099 Ignite SaaS. All Rights Reserved.
        </div>
        {/* LinkedIn Icon - Center on desktop */}
        <div className="flex justify-center w-full md:pb-0 pb-8 md:w-auto md:order-2 order-1">
          <a
            href="https://linkedin.com/in/ggrossi91/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-rose-950"
          >
            <FaLinkedinIn className="text-2xl" />
          </a>
        </div>
        {/* Other Links - For desktop, this needs to be last in the HTML but visually first */}
        <div className="flex justify-center text-xs md:justify-start space-x-4 w-full md:w-auto order-3">
          <a
            href="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-rose-950"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
