import React from "react";
import Hero from "../components/home/Hero";
import Newsletter from "../components/home/Newsletter";
import Footer from "../components/home/Footer";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>From Idea to Micro-SaaS in 2 Weeks - Ignite SaaS</title>
        <meta
          name="description"
          content="Speed from idea to market in 14 days. We're all about validating, building, and supercharging your app's growth. Think of us as your launchpad, turning top growth hacks into software that scales your success 100x. Ready to ignite?"
        />
        {/* Canonical URL */}
        <link rel="canonical" href="https://ignitesaas.io" />
        {/* Robots tag for maximum visibility */}
        <meta name="robots" content="index, follow" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="From Idea to Micro-SaaS in 2 Weeks - Ignite SaaS"
        />
        <meta
          property="og:description"
          content="Speed from idea to market in 14 days. We're all about validating, building, and supercharging your app's growth. Think of us as your launchpad, turning top growth hacks into software that scales your success 100x. Ready to ignite?"
        />
        <meta property="og:url" content="https://ignitesaas.io" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/og-img.png" />{" "}
        {/* Replace URL_TO_YOUR_IMAGE with the actual URL of your promotional image */}
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="From Idea to Micro-SaaS in 2 Weeks - Ignite SaaS"
        />
        <meta
          name="twitter:description"
          content="Speed from idea to market in 14 days. We're all about validating, building, and supercharging your app's growth. Think of us as your launchpad, turning top growth hacks into software that scales your success 100x. Ready to ignite?"
        />
        <meta name="twitter:image" content="/og-img.png" />{" "}
        {/* Replace URL_TO_YOUR_IMAGE with the actual URL of your promotional image */}
      </Helmet>
      <Hero />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Home;
