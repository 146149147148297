import { React, useState } from "react";
import "./styles/Hero.css";
import SurveyModal from "./SurveyModal";

function Hero() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  return (
    <div className="bg-orange-50 text-center p-10 md:p-20 relative">
      <h1 className="mb-4 font-extrabold md:font-bold md:text-sm text-stone-500 flex justify-center items-center gap-2">
        <img
          src="/firebottle-icon.svg"
          alt="Ignite SaaS Logo"
          title="Ignite SaaS Logo"
          style={{ height: "2rem" }}
          className="inline-block"
          height="5px"
        />{" "}
        IGNITE SAAS
      </h1>
      <h2 className="text-stone-950 font-extrabold text-4xl md:text-8xl pb-4 md:mx-90">
        From Idea to{" "}
        <span className="gradient-text">Micro-SaaS in 3 Weeks</span>
      </h2>
      <p className="text-stone-900 text-lg mt-4 md:mx-60 md:text-center text-left">
        Speed from idea to market in 21 days. We're all about validating,
        building, and supercharging your app's growth. Think of us as your
        launchpad, turning a decade of experience into software that scales your
        success 100x. Ready to ignite?
      </p>
      <button
        onClick={openModal}
        className="apply-button border-2 border-stone-900 text-stone-900 hover:bg-stone-900 hover:text-white font-bold py-2 px-4 mt-8 w-full md:w-auto"
      >
        APPLY TO JOIN THE PROGRAM
      </button>
      <br />
      {/* Video Embed */}
      <div className="mt-8 shadow-lg border border-stone-400 inline-block">
        <video controls className="w-full max-w-4xl mx-auto">
          <source src="/program-presentation.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <SurveyModal isOpen={modalIsOpen} onRequestClose={closeModal} />
      <hr className="mt-32 md:mx-52 border-t-1 border-stone-300" />
    </div>
  );
}

export default Hero;
