import React, { useState, useEffect } from 'react';

const calculateTimeLeft = () => {
  const difference = +new Date('2024-04-10T09:00:00-03:00') - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const AnnouncementBar = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const countdown = Object.keys(timeLeft).length
    ? `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.minutes}m ${timeLeft.seconds}s`
    : 'The premiere has started!';

  return (
    <div className="fixed top-0 left-0 right-0 bg-rose-500 text-white text-center p-2 z-50">
      <p>👇 Newsletter Premiere: April 10th, 11 AM UTC → {countdown}. Grant your FREE access! 👇</p>
    </div>
  );
};

export default AnnouncementBar;
