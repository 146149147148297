import React from "react";
import "../../styles/Newsletter.css";

function Newsletter() {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value; // Corrected to access the email input correctly

    try {
      const response = await fetch(
        "https://us-central1-ignite-saas.cloudfunctions.net/subscribeToNewsletter",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        const data = await response.json(); // Parse the JSON response
        window.location.href = data.redirectUrl; // Redirect using the URL provided by the server
      } else {
        console.error("Failed to subscribe");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="p-10 pb-0 flex flex-col md:flex-row bg-orange-50 text-stone-900"
        style={{ fontFamily: "Figtree" }}
      >
        <div className="wording w-full md:flex-1 flex flex-col justify-center py-12 px-4 md:pr-8 md:pl-24 text-left">
          <h2 className="text-1xl md:text-1xl font-bold overflow-hidden pb-8">
            🔥 1000 Products Newsletter
          </h2>
          <h2 className="text-4xl md:text-5xl pb-4 font-bold overflow-hidden">
            Watch Me{" "}
            <span className="text-rose-600">Bootstrap a New Micro-SaaS</span>{" "}
            Every Week!
          </h2>
          <p className="text-lg mt-4 overflow-hidden">
            Dive in every week as we{" "}
            <strong>brainstorm, validate, and launch a fresh Micro-SaaS</strong>
            , equipping you with the insider tools to carve out your own success
            saga!
          </p>
          <div className="mt-4 w-full">
            <div className="flex flex-col sm:flex-row items-center border-2 border-stone-900 p-1 bg-white w-full">
              <div className="sm:flex items-center w-full">
                <input
                  name="email"
                  type="email"
                  placeholder="Your Email Address"
                  className="p-2 flex-grow w-full"
                  style={{ outline: "none" }}
                />
              </div>
              <button
                type="submit"
                className="bg-rose-600 hover:bg-rose-500 px-4 py-2 font-bold text-white w-full sm:w-auto sm:ml-2 mt-2 sm:mt-0 md:w-48" // Added md:w-48 for desktop screens
              >
                Burn, Baby!
              </button>
            </div>
            <p className="font-bold mt-2">
              Pure Strategies, No Nonsense. For the Doers Only.
            </p>
            <p className="text-neutral-500 mt-16 mb-8">Gustavo Grossi, CEO</p>
            <img
              src="/ggrossi-signature.svg"
              alt="Gustavo Grossi Signature"
              title="Gustavo Grossi Signature"
              style={{ width: "30%" }}
            />
          </div>
        </div>
        <div className="picture w-full md:flex-1 flex justify-center items-end">
          <img
            src="/GustavoGrossi.png"
            alt="Gustavo Grossi SaaS Specialist"
            title="Gustavo Grossi SaaS Specialist"
            className="max-w-full h-5/6 rounded-lg"
          />
        </div>
      </form>
    </>
  );
}

export default Newsletter;
