import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import HomeBlog from "./pages/HomeBlog";
import Blog from "./pages/Blog";
import Newsletter from "./pages/Newsletter";
import PrivacyPolicy from "./pages/PrivacyPolicy";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/newsletter" element={<Newsletter />} exact />
        <Route path="/blog/" element={<HomeBlog />} exact />
        <Route path="/blog/:postName" element={<Blog />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
