import React from "react";
import "../styles/PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy p-4">
      <h1>Privacy Policy for Ignite SaaS</h1>
      <section>
        <h2>Introduction</h2>
        <p>
          This Privacy Policy outlines how Ignite SaaS, a service provided by
          Grossi Technology Services and Consultancy LTD (CNPJ
          25.039.922/0001-33), located in Brazil, collects, uses, and protects
          the personal information of its website users. By using our website
          (https://ignitesaas.io), you consent to the data practices described
          in this policy.
        </p>
      </section>

      <section>
        <h2>Data Collection</h2>
        <p>
          We collect personal information through forms on our website. The
          types of personal information collected include:
        </p>
        <ul>
          <li>Name</li>
          <li>Email</li>
          <li>Phone Number</li>
        </ul>
        <p>Use of Cookies</p>
        <p>
          Our website uses cookies to enhance user experience and analyze
          website traffic. Cookies are used in connection with:
        </p>
        <ul>
          <li>Google Ads</li>
          <li>TikTok Ads</li>
          <li>Twitter Ads</li>
          <li>LinkedIn Ads</li>
          <li>Google Analytics</li>
        </ul>
      </section>

      <section>
        <h2>Use of Personal Information</h2>
        <p>
          The personal information we collect is used for the following
          purposes:
        </p>
        <ul>
          <li>To provide and improve our services</li>
          <li>To communicate with users regarding their inquiries</li>
          <li>
            For marketing and promotional efforts, in compliance with applicable
            laws
          </li>
        </ul>
      </section>

      <section>
        <h2>Data Protection</h2>
        <p>
          We are committed to ensuring the security of your personal
          information. We implement a variety of security measures to maintain
          the safety of your personal data.
        </p>
      </section>

      <section>
        <h2>Data Sharing</h2>
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          personally identifiable information. This does not include trusted
          third parties who assist us in operating our website, conducting our
          business, or serving our users, so long as those parties agree to keep
          this information confidential.
        </p>
      </section>

      <section>
        <h2>Your Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal
          information. If you wish to exercise these rights, please contact our
          Data Protection Officer (DPO) at saasignite@gmail.com.
        </p>
      </section>

      <section>
        <h2>Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to update this privacy policy at any time. When
          we do, we will post the updated policy on this page and update the
          policy's effective date.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at:
        </p>
        <p>Email: saasignite@gmail.com</p>
        <p>
          Address: Ladeira Alexandre Leonel, 221, Juiz de Fora, Minas Gerais,
          Brazil
        </p>
      </section>

      <section>
        <h2>Effective Date</h2>
        <p>This policy is effective as of March 14, 2024.</p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
