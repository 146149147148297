import React, { useState } from "react";
import "../styles/HomeNavbarStyles.css";
import { Link } from "react-router-dom";
import { FaShareAlt, FaLink, FaFacebookF, FaLinkedinIn } from "react-icons/fa"; // Import necessary icons
import { FaXTwitter } from "react-icons/fa6";
import { GiHypersonicBolt } from "react-icons/gi";

function HomeNavbar({ visible }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCopiedNotice, setShowCopiedNotice] = useState(false);

  // Function to toggle the dropdown visibility
  const toggleDropdown = () => setShowDropdown(!showDropdown);

  // Function to handle URL copy
  const handleCopyURL = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowCopiedNotice(true);
    setTimeout(() => setShowCopiedNotice(false), 2000); // Hide notice after 2 seconds
  };

  return (
    <div className="navbar" style={{ top: visible ? "0" : "-100px" }}>
      <div className="navbar-content">
      <Link to="/">
          {" "}
          {/* Add Link here */}
          <div className="blog-title">
            <GiHypersonicBolt className="mobile-icon" />
            <span className="desktop-title">Ignite SaaS</span>
          </div>
        </Link>{" "}
        <div className="navbar-actions">
          <button className="cta-button desktop-only">Subscribe</button>
          <FaShareAlt className="share-icon" onClick={toggleDropdown} />
          {showDropdown && (
            <div className="share-dropdown">
              <div className="dropdown-item" onClick={handleCopyURL}>
                <FaLink className="icon" />
                Copy link
              </div>
              <div
                className="dropdown-item"
                onClick={() =>
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      window.location.href
                    )}`,
                    "_blank"
                  )
                }
              >
                <FaFacebookF className="icon" />
                Facebook
              </div>
              <div
                className="dropdown-item"
                onClick={() =>
                  window.open(
                    `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                      window.location.href
                    )}`,
                    "_blank"
                  )
                }
              >
                <FaLinkedinIn className="icon" />
                LinkedIn
              </div>
              <div
                className="dropdown-item"
                onClick={() =>
                  window.open(
                    `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                      window.location.href
                    )}&text=${encodeURIComponent(document.title)}`,
                    "_blank"
                  )
                }
              >
                <FaXTwitter className="icon" />X (ex-Twitter)
              </div>
            </div>
          )}
          {showCopiedNotice && (
            <div className="copied-notice">Link Copied!</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeNavbar;
