import React, { useState, useEffect } from "react";
import matter from "gray-matter";
import { Link } from "react-router-dom";
import "../styles/BlogHomeStyles.css";
import HomeNavbar from "../components/HomeNavbar.js";

function BlogHome() {
  const [posts, setPosts] = useState([]);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [navbarVisible, setNavbarVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setNavbarVisible(lastScrollY > currentScrollY || currentScrollY < 10);
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch(`${process.env.PUBLIC_URL}/posts.json`);
      const postsData = await response.json();
      const posts = await Promise.all(
        postsData.map(async (post) => {
          const content = await fetch(
            `${process.env.PUBLIC_URL}/markdown/${post.slug}.md`
          ).then((res) => res.text());
          const meta = matter(content);
          return {
            ...post,
            description: meta.data.description || "",
            content: meta.content,
          };
        })
      );
      setPosts(posts);
    };
    fetchPosts();
  }, []);

  return (
    <>
      <HomeNavbar visible={navbarVisible} />
      <div className="home-page">
        <h1>Welcome to My Blog</h1>
        <p>Discover the latest insights and stories.</p>
        <div className="posts">
          {posts.map((post, index) => (
            <Link to={`/blog/${post.slug}`} key={index} className="post">
              <h2>{post.title}</h2>
              <p>
                {post.description.length > 100
                  ? `${post.description.substring(0, 100)}...`
                  : post.description}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default BlogHome;
