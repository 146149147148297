import React, { useState } from "react";
import ReactModal from "react-modal";
import { db } from "../../firebase/config";
import { collection, addDoc } from "firebase/firestore"; // Import the necessary functions

function SurveyModal({ isOpen, onRequestClose }) {
  const [showCalendly, setShowCalendly] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: e.target.name.value,
      email: e.target.email.value,
      linkedin: e.target.linkedin.value,
      timestamp: new Date(),
    };

    try {
      // Save the form data to Firestore
      await addDoc(collection(db, "surveyResponses"), formData);
      setShowCalendly(true); // Show Calendly widget after form submission
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
        setShowCalendly(false); // Reset state when modal is closed
      }}
      contentLabel="Survey Modal"
      className="modal"
      overlayClassName="overlay"
    >
      {!showCalendly ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <p className="mb-2 font-bold text-center text-xl">Let's Talk!</p>
            <p>Please complete the form below, and we'll get in touch :)</p>
          </div>
          <div>
            <label htmlFor="name" className="font-bold block">
              Name
            </label>
            <input id="name" type="text" required className="input" />
          </div>

          <div>
            <label htmlFor="email" className="font-bold block">
              Email
            </label>
            <input id="email" type="email" required className="input" />
          </div>

          <div>
            <label htmlFor="linkedin" className="font-bold block">
              LinkedIn URL
            </label>
            <input id="linkedin" type="text" className="input" />
          </div>

          <button
            type="submit"
            className="submit-button font-bold w-full py-2 px-4 mt-4 bg-rose-600 text-white hover:bg-rose-500 rounded shadow-lg"
          >
            Book In a Chat →
          </button>
        </form>
      ) : (
        <iframe
          src="https://calendly.com/ignitesaas/micro-saas-discovery-call?hide_event_type_details=1&hide_gdpr_banner=1"
          style={{ width: "100%", height: "600px", border: "none" }}
        ></iframe>
      )}
      <script
        type="text/javascript"
        src="https://assets.calendly.com/assets/external/widget.js"
        async
      ></script>
    </ReactModal>
  );
}

export default SurveyModal;
