import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { marked } from "marked";
import matter from "gray-matter";
import { Helmet } from "react-helmet";
import "../styles/BlogPostStyles.css";
import Navbar from "../components/Navbar.js";

function Blog() {
  const { postName } = useParams();
  const location = useLocation();
  const [postContent, setPostContent] = useState("");
  const [metaData, setMetaData] = useState({});
  const [lastScrollY, setLastScrollY] = useState(0);
  const [navbarVisible, setNavbarVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setNavbarVisible(lastScrollY > currentScrollY || currentScrollY < 10);
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  useEffect(() => {
    const fetchMarkdownPost = async () => {
      try {
        const response = await fetch(
          `${process.env.PUBLIC_URL}/markdown/${postName}.md`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        const { content, data } = matter(text);
        setPostContent(marked.parse(content));
        setMetaData(data);
      } catch (error) {
        console.error("Failed to fetch post:", error);
      }
    };

    fetchMarkdownPost();
  }, [postName]);

  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  return (
    <>
      <Navbar visible={navbarVisible} />
      <div className="blog-post">
        <Helmet>
          <title>{metaData.title}</title>
          <meta name="description" content={metaData.description} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={metaData.title} />
          <meta name="twitter:description" content={metaData.description} />
          <meta name="twitter:image" content={metaData.ogImage} />
          <meta property="og:title" content={metaData.title} />
          <meta property="og:description" content={metaData.description} />
          <meta property="og:image" content={metaData.ogImage} />
          <meta name="revisit-after" content="7" />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: postContent }}
        />
      </div>
      <div>
        {metaData.authorName && (
          <div className="author-section">
            <div className="author-info">
              {metaData.authorImage && (
                <img
                  src={metaData.authorImage}
                  alt="Author Gustavo Grossi Head of SaaS"
                  title="Author Gustavo Grossi Head of SaaS"
                  className="author-image"
                />
              )}
              <div>
                <h3>Written by {metaData.authorName}</h3>
                <p>{metaData.authorBio}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Blog;
